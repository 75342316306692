exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brand-expressions-color-tsx": () => import("./../../../src/pages/brand-expressions/color.tsx" /* webpackChunkName: "component---src-pages-brand-expressions-color-tsx" */),
  "component---src-pages-brand-expressions-elevation-tsx": () => import("./../../../src/pages/brand-expressions/elevation.tsx" /* webpackChunkName: "component---src-pages-brand-expressions-elevation-tsx" */),
  "component---src-pages-brand-expressions-iconography-tsx": () => import("./../../../src/pages/brand-expressions/iconography.tsx" /* webpackChunkName: "component---src-pages-brand-expressions-iconography-tsx" */),
  "component---src-pages-brand-expressions-imagery-tsx": () => import("./../../../src/pages/brand-expressions/imagery.tsx" /* webpackChunkName: "component---src-pages-brand-expressions-imagery-tsx" */),
  "component---src-pages-brand-expressions-logos-tsx": () => import("./../../../src/pages/brand-expressions/logos.tsx" /* webpackChunkName: "component---src-pages-brand-expressions-logos-tsx" */),
  "component---src-pages-brand-expressions-typography-tsx": () => import("./../../../src/pages/brand-expressions/typography.tsx" /* webpackChunkName: "component---src-pages-brand-expressions-typography-tsx" */),
  "component---src-pages-components-alert-index-tsx": () => import("./../../../src/pages/components/alert/index.tsx" /* webpackChunkName: "component---src-pages-components-alert-index-tsx" */),
  "component---src-pages-components-breadcrumb-index-tsx": () => import("./../../../src/pages/components/breadcrumb/index.tsx" /* webpackChunkName: "component---src-pages-components-breadcrumb-index-tsx" */),
  "component---src-pages-components-button-index-tsx": () => import("./../../../src/pages/components/button/index.tsx" /* webpackChunkName: "component---src-pages-components-button-index-tsx" */),
  "component---src-pages-components-checkbox-index-tsx": () => import("./../../../src/pages/components/checkbox/index.tsx" /* webpackChunkName: "component---src-pages-components-checkbox-index-tsx" */),
  "component---src-pages-components-coach-mark-index-tsx": () => import("./../../../src/pages/components/coach-mark/index.tsx" /* webpackChunkName: "component---src-pages-components-coach-mark-index-tsx" */),
  "component---src-pages-components-combobox-index-tsx": () => import("./../../../src/pages/components/combobox/index.tsx" /* webpackChunkName: "component---src-pages-components-combobox-index-tsx" */),
  "component---src-pages-components-component-status-index-tsx": () => import("./../../../src/pages/components/component-status/index.tsx" /* webpackChunkName: "component---src-pages-components-component-status-index-tsx" */),
  "component---src-pages-components-dropdown-menu-index-tsx": () => import("./../../../src/pages/components/dropdown-menu/index.tsx" /* webpackChunkName: "component---src-pages-components-dropdown-menu-index-tsx" */),
  "component---src-pages-components-dropdown-menu-nav-index-tsx": () => import("./../../../src/pages/components/dropdown-menu-nav/index.tsx" /* webpackChunkName: "component---src-pages-components-dropdown-menu-nav-index-tsx" */),
  "component---src-pages-components-footer-index-tsx": () => import("./../../../src/pages/components/footer/index.tsx" /* webpackChunkName: "component---src-pages-components-footer-index-tsx" */),
  "component---src-pages-components-header-index-tsx": () => import("./../../../src/pages/components/header/index.tsx" /* webpackChunkName: "component---src-pages-components-header-index-tsx" */),
  "component---src-pages-components-heading-index-tsx": () => import("./../../../src/pages/components/heading/index.tsx" /* webpackChunkName: "component---src-pages-components-heading-index-tsx" */),
  "component---src-pages-components-icon-index-tsx": () => import("./../../../src/pages/components/icon/index.tsx" /* webpackChunkName: "component---src-pages-components-icon-index-tsx" */),
  "component---src-pages-components-image-card-index-tsx": () => import("./../../../src/pages/components/image-card/index.tsx" /* webpackChunkName: "component---src-pages-components-image-card-index-tsx" */),
  "component---src-pages-components-input-group-index-tsx": () => import("./../../../src/pages/components/input-group/index.tsx" /* webpackChunkName: "component---src-pages-components-input-group-index-tsx" */),
  "component---src-pages-components-layout-index-tsx": () => import("./../../../src/pages/components/layout/index.tsx" /* webpackChunkName: "component---src-pages-components-layout-index-tsx" */),
  "component---src-pages-components-link-index-tsx": () => import("./../../../src/pages/components/link/index.tsx" /* webpackChunkName: "component---src-pages-components-link-index-tsx" */),
  "component---src-pages-components-loading-spinner-index-tsx": () => import("./../../../src/pages/components/loading-spinner/index.tsx" /* webpackChunkName: "component---src-pages-components-loading-spinner-index-tsx" */),
  "component---src-pages-components-modal-index-tsx": () => import("./../../../src/pages/components/modal/index.tsx" /* webpackChunkName: "component---src-pages-components-modal-index-tsx" */),
  "component---src-pages-components-pagination-index-tsx": () => import("./../../../src/pages/components/pagination/index.tsx" /* webpackChunkName: "component---src-pages-components-pagination-index-tsx" */),
  "component---src-pages-components-progress-bar-index-tsx": () => import("./../../../src/pages/components/progress-bar/index.tsx" /* webpackChunkName: "component---src-pages-components-progress-bar-index-tsx" */),
  "component---src-pages-components-radio-button-index-tsx": () => import("./../../../src/pages/components/radio-button/index.tsx" /* webpackChunkName: "component---src-pages-components-radio-button-index-tsx" */),
  "component---src-pages-components-select-index-tsx": () => import("./../../../src/pages/components/select/index.tsx" /* webpackChunkName: "component---src-pages-components-select-index-tsx" */),
  "component---src-pages-components-sidenav-index-tsx": () => import("./../../../src/pages/components/sidenav/index.tsx" /* webpackChunkName: "component---src-pages-components-sidenav-index-tsx" */),
  "component---src-pages-components-switch-index-tsx": () => import("./../../../src/pages/components/switch/index.tsx" /* webpackChunkName: "component---src-pages-components-switch-index-tsx" */),
  "component---src-pages-components-tabs-index-tsx": () => import("./../../../src/pages/components/tabs/index.tsx" /* webpackChunkName: "component---src-pages-components-tabs-index-tsx" */),
  "component---src-pages-components-text-input-index-tsx": () => import("./../../../src/pages/components/text-input/index.tsx" /* webpackChunkName: "component---src-pages-components-text-input-index-tsx" */),
  "component---src-pages-components-textarea-index-tsx": () => import("./../../../src/pages/components/textarea/index.tsx" /* webpackChunkName: "component---src-pages-components-textarea-index-tsx" */),
  "component---src-pages-components-toast-index-tsx": () => import("./../../../src/pages/components/toast/index.tsx" /* webpackChunkName: "component---src-pages-components-toast-index-tsx" */),
  "component---src-pages-components-toggle-button-group-index-tsx": () => import("./../../../src/pages/components/toggle-button-group/index.tsx" /* webpackChunkName: "component---src-pages-components-toggle-button-group-index-tsx" */),
  "component---src-pages-components-tooltip-index-tsx": () => import("./../../../src/pages/components/tooltip/index.tsx" /* webpackChunkName: "component---src-pages-components-tooltip-index-tsx" */),
  "component---src-pages-content-style-guide-editing-checklist-tsx": () => import("./../../../src/pages/content-style-guide/editing-checklist.tsx" /* webpackChunkName: "component---src-pages-content-style-guide-editing-checklist-tsx" */),
  "component---src-pages-content-style-guide-grammar-and-style-index-tsx": () => import("./../../../src/pages/content-style-guide/grammar-and-style/index.tsx" /* webpackChunkName: "component---src-pages-content-style-guide-grammar-and-style-index-tsx" */),
  "component---src-pages-content-style-guide-jstor-terms-tsx": () => import("./../../../src/pages/content-style-guide/jstor-terms.tsx" /* webpackChunkName: "component---src-pages-content-style-guide-jstor-terms-tsx" */),
  "component---src-pages-content-style-guide-voice-and-tone-index-tsx": () => import("./../../../src/pages/content-style-guide/voice-and-tone/index.tsx" /* webpackChunkName: "component---src-pages-content-style-guide-voice-and-tone-index-tsx" */),
  "component---src-pages-content-style-guide-web-elements-tsx": () => import("./../../../src/pages/content-style-guide/web-elements.tsx" /* webpackChunkName: "component---src-pages-content-style-guide-web-elements-tsx" */),
  "component---src-pages-contributing-development-index-tsx": () => import("./../../../src/pages/contributing/development/index.tsx" /* webpackChunkName: "component---src-pages-contributing-development-index-tsx" */),
  "component---src-pages-contributing-documentation-index-tsx": () => import("./../../../src/pages/contributing/documentation/index.tsx" /* webpackChunkName: "component---src-pages-contributing-documentation-index-tsx" */),
  "component---src-pages-design-tokens-alias-colors-tsx": () => import("./../../../src/pages/design-tokens/alias-colors.tsx" /* webpackChunkName: "component---src-pages-design-tokens-alias-colors-tsx" */),
  "component---src-pages-design-tokens-elevation-tsx": () => import("./../../../src/pages/design-tokens/elevation.tsx" /* webpackChunkName: "component---src-pages-design-tokens-elevation-tsx" */),
  "component---src-pages-design-tokens-font-family-tsx": () => import("./../../../src/pages/design-tokens/font-family.tsx" /* webpackChunkName: "component---src-pages-design-tokens-font-family-tsx" */),
  "component---src-pages-design-tokens-font-size-tsx": () => import("./../../../src/pages/design-tokens/font-size.tsx" /* webpackChunkName: "component---src-pages-design-tokens-font-size-tsx" */),
  "component---src-pages-design-tokens-font-weight-tsx": () => import("./../../../src/pages/design-tokens/font-weight.tsx" /* webpackChunkName: "component---src-pages-design-tokens-font-weight-tsx" */),
  "component---src-pages-design-tokens-global-colors-tsx": () => import("./../../../src/pages/design-tokens/global-colors.tsx" /* webpackChunkName: "component---src-pages-design-tokens-global-colors-tsx" */),
  "component---src-pages-design-tokens-line-height-tsx": () => import("./../../../src/pages/design-tokens/line-height.tsx" /* webpackChunkName: "component---src-pages-design-tokens-line-height-tsx" */),
  "component---src-pages-design-tokens-overview-tsx": () => import("./../../../src/pages/design-tokens/overview.tsx" /* webpackChunkName: "component---src-pages-design-tokens-overview-tsx" */),
  "component---src-pages-design-tokens-radius-tsx": () => import("./../../../src/pages/design-tokens/radius.tsx" /* webpackChunkName: "component---src-pages-design-tokens-radius-tsx" */),
  "component---src-pages-design-tokens-spacing-tsx": () => import("./../../../src/pages/design-tokens/spacing.tsx" /* webpackChunkName: "component---src-pages-design-tokens-spacing-tsx" */),
  "component---src-pages-design-tokens-transitions-tsx": () => import("./../../../src/pages/design-tokens/transitions.tsx" /* webpackChunkName: "component---src-pages-design-tokens-transitions-tsx" */),
  "component---src-pages-design-tokens-type-scale-tsx": () => import("./../../../src/pages/design-tokens/type-scale.tsx" /* webpackChunkName: "component---src-pages-design-tokens-type-scale-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-getting-started-tsx": () => import("./../../../src/pages/getting-started.tsx" /* webpackChunkName: "component---src-pages-getting-started-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-styles-type-styles-tsx": () => import("./../../../src/pages/styles/type-styles.tsx" /* webpackChunkName: "component---src-pages-styles-type-styles-tsx" */)
}

